<template>
  <div>
    <div class="label__search_container">
      <label class="label_input_search">{{ label }}</label>
      <div>
        <div class="custom-select">
          <div class="selected" :class="{ open: open, notSelectedAfterBlur: showErrorMessage }">
            <span v-if="!optionLabel" class="custom-select-placeholder">{{ placeholder }}</span>
            <span v-else>{{ optionLabel }}</span>
            <svg v-show="!open" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="m5 7.5 5 5 5-5" stroke="#737373" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-show="open" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="m5 12.5 5-5 5 5" stroke="#737373" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="items" v-show="open">
            <div v-if="optionList.length > 0" class="items_list">
              <div
                v-for="(option, i) of optionList"
                :key="i"
                @click="onClickElement(option)"
              >
                <div v-if="isForScharffPoints" class="item_option forScharffPoint">
                  <span class="point-name">{{ option.Name }}</span>
                  <div class="address_and_schedule">
                    <span class="address">{{ option.Address }}</span>
                    <span class="schedule">9:00 a. m. - 8:00 p. m.</span>
                  </div>
                </div>
                <div v-else class="item_option">
                  {{ option.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorMessage v-if="showErrorMessage" :message="errorMessage"/>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorMessage from '../ErrorMessage.vue'

export default {
  components: {
    ErrorMessage,
  },
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isForScharffPoints: {
      type: Boolean,
      default: false
    },
    optionList: {
      type: Array,
      required: true
    },
    errorMessage: {
      type: String,
      default: 'Error'
    },
    showEmptyInputError: {
      type: Boolean,
      default: false
    },
    isOptional: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      notSelectedAfterBlur: false,
      isClickedInside: false
    }
  },
  methods: {
    onClickElement(option) {
      setTimeout(() => {
        this.open = false
      }, 50)
      this.notSelectedAfterBlur = false
      this.$emit('input', option?.ScharffPointId || option?.id)
      this.$emit('change', option)
    },
    handleClickOutside(event) {
      // Para checar si se hizo click dentro o fuera de todo esto
      const clickedComponent = event.target
      const isClickedInside = this.$el.contains(clickedComponent)
      this.isClickedInside = isClickedInside

      if (this.open && !isClickedInside) {
        this.notSelectedAfterBlur = !this.optionLabel
      }

      this.open = isClickedInside
    }
  },
  created () {
    this.$watch('showEmptyInputError', value => {
      if(value) {
        this.notSelectedAfterBlur = true
      }
    })
    document.addEventListener('click', this.handleClickOutside)
  },
  computed: {
    optionLabel() {
      const optionSelected = this.optionList.find(option => {
        const optionvalue = option?.ScharffPointId || option?.id
        return optionvalue === this.value
      })
      return optionSelected?.Name || optionSelected?.label || ''
    },
    showErrorMessage(){
      return this.isOptional  && this.notSelectedAfterBlur
    }
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
}
</script>

<style lang="scss" scoped>
.label__search_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.label_input_search {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #1A1A1A !important;
  text-align: left;
}
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
}
.custom-select .selected {
  padding: 0 16px;
  cursor: pointer;
  user-select: none;
  min-height: 50px;
  border: 1px solid #737373;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-select .selected:hover {
  border: 1px solid #1A1A1A;
}
.custom-select .selected.open { border: 2px solid #1A1A1A }
.custom-select .selected.notSelectedAfterBlur { border: 1px solid #E50000 }
.custom-select-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #737373;
}
.custom-select .items {
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 250px;
  overflow: overlay; /* para el scroll sea sobre las coasas */
  margin-top: 5px;
}
.item_option {
  cursor: pointer;
  user-select: none;
  font-style: normal;
  color: #1A1A1A;
  padding: 12px 16px;
  &.forScharffPoint{
    line-height: 47px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 1em;
  }

  .point-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .address_and_schedule {
    display: flex;
    flex-direction: column;

    .address {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .schedule {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #737373;
    }
  }
}
.custom-select .items .items_list > div:not(:last-child) {
  border-bottom: 1px solid #DEDEDE;
}
.item_option:hover { background: rgba(0, 0, 0, 0.05) }
.item_option:active { background: rgba(0, 0, 0, 0.15) }

.custom-select .items::-webkit-scrollbar {
  width: 15px;
}
.custom-select .items::-webkit-scrollbar-thumb {
  background: rgba(26, 26, 26, 0.5);
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 15px;
}
</style>